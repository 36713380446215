import { createApp } from 'vue';
import router from './router';
import http from '@/libs/http';
import components from './components';
import elementPlus from 'element-plus';
import locale from 'element-plus/es/locale/lang/zh-cn';
import dayjs from 'dayjs';
import '@/libs/baidu-map';
import 'element-plus/theme-chalk/index.css';
import './style.scss';
import App from './app.vue';

createApp(App)
  .use(router)
  .use(elementPlus, { locale })
  .use(components)
  .use(app => {
    app.config.globalProperties.$http = http;
    app.config.globalProperties.$console = console;
    app.config.globalProperties.$dayjs = dayjs;
    app.config.unwrapInjectedRef = true;
  })
  .mount('#app');
