import { m__mock, mock, Random } from '@chinamo/mock';
import news1_1 from './image/news1-1.png';
import news1_2 from './image/news1-2.jpg';
import news2_1 from './image/news2-1.jpg';
import news2_2 from './image/news2-2.jpg';
import news2_3 from './image/news2-3.jpg';
import news2_4 from './image/news2-4.jpg';
import news3_1 from './image/news3-1.jpg';
import news4_1 from './image/news4-1.jpg';
import news4_2 from './image/news4-2.jpg';
import news5_1 from './image/news5-1.jpg';
import news5_2 from './image/news5-2.jpg';
import news5_3 from './image/news5-3.jpg';
import news6_1 from './image/news6-1.jpg';
import news6_2 from './image/news6-2.jpg';
import news6_3 from './image/news6-3.jpg';
import news6_4 from './image/news6-4.jpg';
import news7_1 from './image/news7-1.jpg';
import news7_2 from './image/news7-2.jpg';
import news7_3 from './image/news7-3.jpg';
import news7_4 from './image/news7-4.jpg';
import news7_5 from './image/news7-5.jpg';
import news8_1 from './image/news8-1.jpg';
import news8_2 from './image/news8-2.jpg';
import news9_1 from './image/news9-1.jpg';
import news9_2 from './image/news9-2.jpg';
import news10_1 from './image/news10-1.jpg';
import news10_2 from './image/news10-2.jpg';
import news10_3 from './image/news10-3.jpg';

const news = [
  {
    id: 1,
    title: '“江海直达+长江班列” 构建入川货运快速通道',
    summary: '5月7日，一列满载30个集装箱的“长江班列”成功将780吨巴西纸浆从荆州沙市南站运抵德阳黄许站，以“江海直达+长江班列”江铁海联运新模式实现了16000多公里超长距离跨境一站式物流交付。',
    thumbnail: { path: news1_1 },
    content: `<p><span style="font-family: 'PingFang SC', system-ui, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Hiragino Sans GB', 'Microsoft YaHei UI', 'Microsoft YaHei', Arial, sans-serif; font-size: 16px; letter-spacing: 2px; text-align: justify; text-indent: 35px; background-color: #ffffff;">&nbsp; &nbsp; 5月7日，一列满载30个集装箱的&ldquo;长江班列&rdquo;成功将780吨巴西纸浆从荆州沙市南站运抵德阳黄许站，以&ldquo;江海直达+长江班列&rdquo;江铁海联运新模式实现了16000多公里超长距离跨境一站式物流交付。</span></p>
        <p><span style="font-family: 'PingFang SC', system-ui, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Hiragino Sans GB', 'Microsoft YaHei UI', 'Microsoft YaHei', Arial, sans-serif; font-size: 16px; letter-spacing: 2px; text-align: justify; text-indent: 35px; background-color: #ffffff;"><img src="${news1_1}" alt="" width="1080" height="651" /></span></p>
        <p><span style="text-align: justify; text-indent: 35px; background-color: #ffffff;"><span style="font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;"><span style="letter-spacing: 2px;">&nbsp; &nbsp; 此批货物由巴西桑托斯港海运到达上海港后，经长江水道驳运至荆州港无缝换乘&ldquo;长江班列&rdquo;直抵德阳黄许站，较传统经三峡运输节约十余天时间，有效构建起了与国际航运间的快速通道，进一步加快了入川&ldquo;门到门&rdquo;货运速度。</span></span></span></p>
        <p><span style="text-align: justify; text-indent: 35px; background-color: #ffffff;"><span style="font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;"><span style="letter-spacing: 2px;"><img src="${news1_2}" alt="" /></span></span></span></p>
        <p><span style="text-align: justify; text-indent: 35px; background-color: #ffffff;"><span style="font-family: PingFang SC, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;"><span style="letter-spacing: 2px;">&nbsp; &nbsp; 自4月以来，&ldquo;长江班列&rdquo;持续发挥铁水联运运输比较优势，在降低物流综合成本上下功夫，通过联动长江沿线政府、企业，于4月下旬成功开通（舟山-黄石-川渝）&ldquo;江海直达+长江班列&rdquo;江铁海联运新模式，发送7000吨大豆至成都、广元地区，以物流模式创新不断提升物流综合服务效能，助力实体企业降本增效和区域经济社会发展。</span></span></span></p>`,
    releaseDate: '2024-05-14 13:04:21',
    isTop: false,
    isHot: false,
    hits: Random.natural(10, 100)
  },
  {
    id: 2,
    title: '灵活组织降成本 “长江班列”再提速',
    summary: '“长江班列”充分发挥全天候运输通道优势，以“铁水联运”大幅缩短运输时间，有效降低企业物流综合成本，使川渝地区与长江中下游地区货物交流效率显著提升。以从成都至上海地区为例，在航路拥堵期间，货物经“长江班列”到达武汉后无缝衔接水运至上海，平均运输时间较传统的水运直达方式至少缩短15天。',
    thumbnail: { path: news2_1 },
    content: `<p>&nbsp; &nbsp; &nbsp; 3月31日，随着一声悠长的汽笛声划破天空，满载板材的&ldquo;长江班列&rdquo;迎着朝阳缓缓驶出成都国际铁路港，驶往湖北武汉香炉山站。</p>
        <p><img src="${news2_1}" alt="" width="1080" height="651" /></p>
        <p>&nbsp; &nbsp; &nbsp; 一季度，&ldquo;长江班列&rdquo;累计发运434列，其中东出251列，西进183列，共21532个标准集装箱，以&ldquo;铁水联运&rdquo;优势加速聚合区域要素，正成为牵引区域融通新力量。</p>
        <p style="text-align: center;"><strong>跑出物流降本增效&ldquo;加速度&rdquo;</strong></p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;长江班列&rdquo;充分发挥全天候运输通道优势，以&ldquo;铁水联运&rdquo;大幅缩短运输时间，有效降低企业物流综合成本，使川渝地区与长江中下游地区货物交流效率显著提升。以从成都至上海地区为例，在航路拥堵期间，货物经&ldquo;长江班列&rdquo;到达武汉后无缝衔接水运至上海，平均运输时间较传统的水运直达方式至少缩短15天。自班列运营以来，蜀道集团充分发挥省级平台优势，不断加强省内东向出川货源组织力度，持续提高班列平台服务质量。3月，班列单月开行量和货物发送量创历史新高，达168列，环比增长22%，运载货物22.3万吨，提前半年达到150列/月的规划开行目标。</p>
        <p><img src="${news2_2}" alt="" width="1080" height="651" /></p>
        <p style="text-align: center;"><strong>助力全省高端制造业&ldquo;再升级&rdquo;</strong></p>
        <p>&nbsp; &nbsp; &nbsp; 按照&ldquo;链接产销两端、精准配套供应链&rdquo;的原则，坚持&ldquo;试点引导、逐步拓展、有序推进&rdquo;的工作路径，&ldquo;长江班列&rdquo;积极助力各类产业发展要素导入，不断提升供应链韧性和服务水平，提升资源要素配置效率。当前，&ldquo;长江班列&rdquo;的货物运输已从前期的以省内资源消耗型企业生产原料为主，逐步发展为生产原料与汽车零部件、机械装备、食品医药、百货类等30余种高附加值产品并重的运输格局。同时，2月以来，&ldquo;长江班列&rdquo;东向出川班列数量环比增长超50%，以高效率、低成本的物流优势助力高端制造业在全省布局发展，正成为推动产业升级和营商环境优化的催化剂。</p>
        <p><img src="${news2_3}" alt="" width="1080" height="651" /></p>
        <p style="text-align: center;"><strong>催生长江经济带区域合作&ldquo;大平台&rdquo;</strong></p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;长江班列&rdquo;在通道建设上持续推进、统筹规划，积极开辟新线路，不断增强境内外通达能力，目前已从发运初期的单一线路、少量站点快速拓展到30条联运线路、17个到发站，初步形成&ldquo;以成都至荆州为主线、其他多线协同联动&rdquo;的货物运行网络格局。积极与&ldquo;长江班列&rdquo;沿线30余家企事业单位及武汉、荆州、宜昌等地市交流沟通，通过完善&ldquo;长江班列&rdquo;物流节点、搭建班列资源和数据信息共享平台、探索&ldquo;物流+金融+贸易&rdquo;供应链服务模式等务实举措，合力共商共建高质量&ldquo;长江班列&rdquo;物流服务体系，提升长江黄金水道运输效率，促进长江经济带联动发展。</p>
        <p><img src="${news2_4}" alt="" width="1080" height="651" /></p>
        <p>近日，蜀道铁路运营集团赴湖北湖南广泛开展共话班列活动图为3月在黄石举行&ldquo;江海直达+长江班列&rdquo;战略合作洽谈会暨签约仪式</p>
        <p>&nbsp; &nbsp; &nbsp; 下一步，蜀道铁路运营集团将充分发挥&ldquo;长江班列&rdquo;链接上下游的纽带作用，进一步加强与沿线各省的交流合作，继续在提质增效降本上下功夫，不断提升班列服务能力，更好服务融入国内国际双循环发展格局，助推我省高质量发展和高水平对外开放。</p>
        <p>&nbsp; &nbsp; &nbsp; 预计到2024年底，&ldquo;长江班列&rdquo;将实现双向运输1800列，运输货物超240万吨。</p>
        <p>&nbsp;</p>`,
    releaseDate: '2024-04-02 16:17:04',
    isTop: false,
    isHot: false,
    hits: Random.natural(10, 100)
  },
  {
    id: 3,
    title: '4月，“长江班列”开行质量稳步提升',
    summary: '4月，“长江班列”稳定常态化运行，开行160列，运输货物8068标箱，其中东出67列、西进93列。',
    thumbnail: { path: news3_1 },
    content: `<p>&nbsp; &nbsp; &nbsp; 4月，&ldquo;长江班列&rdquo;稳定常态化运行，开行160列，运输货物8068标箱，其中东出67列、西进93列。</p>
        <p><img src="${news3_1}" alt="" width="1080" height="809" /></p>
        <p>&nbsp; &nbsp; &nbsp; 4月21日，首趟7000吨大豆于舟山港转载后，经长江水道抵达黄石新港，转运&ldquo;长江班列&rdquo;至成都普兴站和广元安家湾站，&ldquo;长江班列&rdquo;正式开启了&ldquo;长江班列+江海直达&rdquo;的多式联运物流新模式，较传统的全程水运减少耗时约2/3，较全程铁路运输降低成本约1/3。</p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;长江班列&rdquo;首发至4月底，共计开行627列，其中东出336列、西进291列，运输货物31296标箱、82.9万吨，形成开行线路30条，发到站点18个。</p>
        <p>&nbsp; &nbsp; &nbsp; 蜀道铁路运营集团通过跟踪市场动态，研究货源分布及流向，掌握运输行业价格波动情况，分析市场需求及偏好，分析现有客户及已开通线路运营情况，设计新路线、新品类价格，更新现有路线、报价。同时，加强&ldquo;长江班列&rdquo;对外宣传工作，拓展潜在客户，帮助客户解决业务流程中疑难问题，强化各铁路专用线、站点的日常维护，不断提升&ldquo;长江班列&rdquo;的开行质量。</p>
        <p>&nbsp;</p>`,
    releaseDate: '2024-05-12 23:34:15',
    isTop: false,
    isHot: true,
    hits: Random.natural(10, 100)
  },
  {
    id: 4,
    title: '川观新闻丨四川“长江班列”实现常态化运行 全新东向出川物流大通道初步成形',
    summary: '6月3日，一列来自湖北的“长江班列”抵达位于成都的城厢站，装载的汽车零配件随即被转运到附近汽车生产厂。“从上海到成都，半个月就运到了，比以前纯走水路至少快5天！”组货公司物流部高级主管葛文静说，这条铁水联运的新路线已成为公司货物西向抵川的必选',
    thumbnail: { path: news4_1 },
    content: `<p>&nbsp; &nbsp; &nbsp; 6月3日，一列来自湖北的&ldquo;长江班列&rdquo;抵达位于成都的城厢站，装载的汽车零配件随即被转运到附近汽车生产厂。&ldquo;从上海到成都，半个月就运到了，比以前纯走水路至少快5天！&rdquo;组货公司物流部高级主管葛文静说，这条铁水联运的新路线已成为公司货物西向抵川的必选。</p>
        <p>&nbsp; &nbsp; &nbsp; 服务于长江经济带的&ldquo;长江班列&rdquo;，以四川和湖北为铁路运营段，绕过三峡翻坝段，采取铁水联运方式，助力特色产品出川、生产原料进川，于2023年12月10日首发。</p>
        <p>&nbsp; &nbsp; &nbsp; 数据显示，截至今年5月底，近半年时间，&ldquo;长江班列&rdquo;已开行769列，运输货物38422标箱、102万吨，形成开行线路30条，发到站点18个，东出班列与西进班列数量比例基本呈现1:1的状态，并与抵川中欧班列实现了有效衔接，一条新的东向出川物流大通道已初步构建成形。</p>
        <p>&nbsp;</p>
        <p style="text-align: center;"><span style="font-size: 24pt; color: #1a316c;">新谋划</span></p>
        <p style="text-align: center;"><span style="font-size: 24pt; color: #1a316c;">铁水联运，打造东向出川型通道</span></p>
        <p>&nbsp; &nbsp; &nbsp; 葛文静所在的成都交投国际供应链管理有限公司，代理了进川大部分的汽车零配件、化工类货物。其中，汽车零配件的客户是沃尔沃、一汽等在川汽车生产厂。以前，这些货物主要通过水运运输，逆长江而上，到达重庆果园港或泸州港，再经汽车或火车转运到目的地，全程用时20&mdash;30天。</p>
        <p>&nbsp; &nbsp; &nbsp; 无论是在重庆或在四川上岸，一个绕不开的&ldquo;坎&rdquo;是三峡枢纽。长江&ldquo;黄金水道&rdquo;辐射的长江经济带横跨我国东中西三大区域，串联了长三角、长江中游和成渝三大城市群，货运量占全国40%以上。长江三峡枢纽投运20年来过闸运量快速增长，2022年就达设计限值1.6亿吨，平均待闸时间已从2011年的17小时（0.7天）增加至2022年的223小时（约9.3天），货船排队等候已成常态。</p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;汽车零配件一般排队一周左右。&rdquo;葛文静说，有一次排队时间较长，比平时晚了好几天，工厂等着零配件上线，客户急得不停打电话催。</p>
        <p>&nbsp; &nbsp; &nbsp; 据调研，长江干线的主要货种，公路运费是铁路运费的3倍左右，铁路运费是水路运费的4倍左右。因此，虽然水路运输时间长，但仍有不少货主选择长江航运。对于&ldquo;翻坝难&rdquo;这一&ldquo;肠梗阻&rdquo;，近年来，陆续有公路翻坝、铁路翻坝方式出现。</p>
        <p>&nbsp; &nbsp; &nbsp; 从现实来看，公路翻坝方式运能较低、运输承载能力有限，并且需另行计算装卸费、公路运费、通行费等，整体翻坝运输成本较高，比如，干散货类需增加翻坝费用约80元/吨，集装箱增加翻坝费用约1500元/标箱。而铁路运输承载能力大、综合运价较低。综合考量下，四川决定开行四川至湖北的&ldquo;长江班列&rdquo;，由蜀道集团作为省级运营主体，采取铁水联运的方式，分流货物上岸，有效缓解过闸拥堵。</p>
        <p>&nbsp; &nbsp; &nbsp; 蜀道铁路运营集团董事长张锋介绍，近半年来，省级平台与长江中游联运港口积极联动，不断优化转运效率，已初步形成&ldquo;以成都至荆州为主线、其他多线协同联动&rdquo;的多点多线货物运行网络联动格局，省内有成都、眉山、攀枝花、达州等发到站，省外有荆州港、鄂州港、阳逻港等多个港口，形成了对三峡通闸拥堵的有效分流，构建起一条快捷价优的东向出川物流新通道。</p>
        <p>&nbsp; &nbsp; &nbsp; 从运行情况来看，目前&ldquo;长江班列&rdquo;较原有水运线路平均缩短运行时间约5&mdash;10天，极大地满足了适水货物的时效需求。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news4_1}" alt="" width="1080" height="810" /></p>
        <p style="text-align: center;"><span style="font-size: 10pt; color: #ced4d9;">&nbsp; &nbsp; &nbsp; 成都青白江城厢中心站，&ldquo;长江班列&rdquo;所运载的汽车零配件正在卸货转运。</span></p>
        <p style="text-align: center;">&nbsp;</p>
        <p style="text-align: center;"><span style="font-size: 24pt; color: #1a316c;">新通道</span></p>
        <p style="text-align: center;"><span style="font-size: 24pt; color: #1a316c;">平均每天开行5列，已实现江铁海联运</span></p>
        <p>&nbsp; &nbsp; &nbsp; 从数据来看，不到半年，&ldquo;长江班列&rdquo;就开行了769列，平均每天4.5列，3月以来已达到平均每天5列。</p>
        <p>&nbsp; &nbsp; &nbsp; 一条新班列，缘何能吸引这么多货源？</p>
        <p>&nbsp; &nbsp; &nbsp; 近半年来，经荆州市地方铁路有限公司到发的&ldquo;长江班列&rdquo;已有7700余箱。该公司副总工程师李春华表示，&ldquo;长江班列&rdquo;在时效和成本上均有优势，不仅大幅缩短了货物运输时间，在政策引导支持下，综合运输成本也比传统方式低，实现了物畅其流。</p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;客户都是用&lsquo;脚&rsquo;投票的。&rdquo;在葛文静看来，在整体运价差别不大的情况下，&ldquo;长江班列&rdquo;把汽车零配件等生产类原料更快地送到客户手中，有助于提高生产运转周期，对客户有着极强的吸引力。</p>
        <p>&nbsp; &nbsp; &nbsp; 为破解&ldquo;翻坝难&rdquo;而开行的&ldquo;长江班列&rdquo;，正在成为新通道。今年一季度，三峡北线船闸和葛洲坝一号船闸检修为期45天，待闸压力进一步加大，通过&ldquo;长江班列&rdquo;有效解决了中粮、中储粮、川威等十余家省内相关企业对生产资料时效性的需求，切实降低了企业的物流综合成本。</p>
        <p>&nbsp; &nbsp; &nbsp; 再细剖数据，开行的769列班列中，东出404列、西进365列，出川、进川班列数比例达到1:1。四川&ldquo;消费型&rdquo;经济特征明显，长期以来，到达货物量都高于运出货物量，缘何&ldquo;长江班列&rdquo;的出川数更多？</p>
        <p>&nbsp; &nbsp; &nbsp; 攀西地区钛精矿年产四五百万吨，约有三百万吨外销。今年以来&ldquo;长江班列&rdquo;，几乎每天都有2&mdash;3列搭载钛精矿的班列，从位于攀枝花的迤资、青杠等火车站驶出。</p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;去年一季度，通过铁路运输到我省主要港口&mdash;&mdash;泸州港下水的只有2万吨左右。&rdquo;蜀道铁路运营集团运输营销部负责人罗胜摆出一组数据：&ldquo;而今年一季度通过&lsquo;长江班列&rsquo;发运的有16万吨，更多货物被吸引&lsquo;公转铁&rsquo;了。&rdquo;</p>
        <p>&nbsp; &nbsp; &nbsp;和中欧班列有效衔接，成为了最大&ldquo;增量&rdquo;。&ldquo;每天抵达城厢站的中欧班列中，约有1/3的集装箱需要转运到华东、华南地区，其中70%通过&lsquo;长江班列&rsquo;运输。&rdquo;成都海程帮达供应链管理有限公司&ldquo;长江班列&rdquo;项目现场负责人黄昌照说，更快的时效、更低的价格，以及便捷的中转，让两大班列在今年3月实现衔接。截至目前，仅城厢站就已发运&ldquo;长江班列&rdquo;逾6500标箱。</p>
        <p><span style="font-size: 10pt; color: #ced4d9;"><img style="display: block; margin-left: auto; margin-right: auto;" src="${news4_2}" alt="" width="1080" height="810" /></span></p>
        <p style="text-align: center;"><span style="font-size: 10pt; color: #ced4d9;">&nbsp; &nbsp; &nbsp;成都青白江城厢中心站，&ldquo;长江班列&rdquo;所转运分拨的欧洲地区货物正等待牵引发出。</span></p>
        <p>&nbsp; &nbsp; &nbsp;&ldquo;长江班列&rdquo;还在向更远的江海延伸。今年4月下旬，长江沿线的政府、企业联动，成功将780吨巴西进口的纸浆，从浙江舟山经湖北黄石、荆州运抵四川德阳，以&ldquo;江海直达+长江班列&rdquo;江铁海联运新模式实现了1.6万余公里超长距离的跨境一站式物流交付，搭建起&ldquo;长江班列&rdquo;与国际航运间的快速连接。</p>
        <p>&nbsp; &nbsp; &nbsp;蜀道集团正携手长江中下游的荆州、鄂州、黄石、岳阳、舟山等，打造&ldquo;江海直达+长江班列&rdquo;的&ldquo;1+N+1&rdquo;水铁联运新模式，N为长江沿岸的多个港口。&ldquo;&lsquo;江海直达&rsquo;最远可覆盖长江90%的航段。&rdquo;在舟山市港航和口岸管理局副局长曹军看来，这种携手合作将优化综合运输结构，做大长江中上游港口联运量，更好地服务长江经济带发展。</p>
        <p>&nbsp; &nbsp; &nbsp;目前，&ldquo;长江班列&rdquo;货物运输种类已从省内资源消耗型企业生产原料为主，逐步扩展到汽车零配件、机械装备、食品医药、百货类等30余种高附加值产品。</p>
        <p>&nbsp; &nbsp; &nbsp;张锋表示，&ldquo;长江班列&rdquo;不仅是物流大通道，更要成为产业大通道，&ldquo;要以高效率、低成本的物流优势，助力高端制造业在全省布局发展，并吸引更多先进生产要素在四川集聚。&rdquo;</p>
        <p>&nbsp;</p>`,
    releaseDate: '2024-06-05 23:24:35',
    isTop: false,
    isHot: false,
    hits: Random.natural(10, 100)
  },
  {
    id: 5,
    title: '助力企业降本增效 “长江班列”为高质量发展添活力',
    summary: '今年以来，“长江班列”累计开行886列，运输货物44542标箱、122万吨，已基本实现平均5列/日稳定开行，以铁水联运比较优势大幅降低沿线实体企业物流成本，有力畅通了川渝东向物贸通道，助力优化我省营商环境，进一步服务和融入国内国际双循环。',
    thumbnail: { path: news5_1 },
    content: `<p>&nbsp; &nbsp; &nbsp; 今年以来，&ldquo;长江班列&rdquo;累计开行886列，运输货物44542标箱、122万吨，已基本实现平均5列/日稳定开行，以铁水联运比较优势大幅降低沿线实体企业物流成本，有力畅通了川渝东向物贸通道，助力优化我省营商环境，进一步服务和融入国内国际双循环。</p>
        <p>&nbsp;</p>
        <p style="text-align: center;"><strong>增加运行新线路</strong></p>
        <p style="text-align: center;"><strong>推动区域经济发展</strong></p>
        <p>&nbsp; &nbsp; &nbsp; 作为蜀道集团在铁路板块的专业化运营平台，蜀道铁路运营集团在通道建设上持续推进、统筹规划，积极开辟新线路，已与长江中下游的荆州、鄂州、黄石、岳阳、舟山等数十个市（州）、单位共同聚焦&ldquo;长江班列&rdquo;高效开行建立了战略合作关系。截止目前，&ldquo;长江班列&rdquo;由开行初期的1条路线、2个站点拓展至开行线路30条，发到站点18个，不断延伸和拓展服务网络，持续提升班列服务质效和区域交通支撑力，畅通长江黄金通道，推动实现&ldquo;经济通道&rdquo;迈向&ldquo;通道经济&rdquo;。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news5_1}" alt="" width="1080" height="608" /></p>
        <p style="text-align: center;"><strong>有效衔接中欧班列</strong></p>
        <p style="text-align: center;"><strong>构建东向出川物流大通道</strong></p>
        <p>&nbsp; &nbsp; &nbsp; 欧洲到华东地区的钾肥、板材、橡胶、荞麦等进口货物，经中欧班列&ldquo;成渝号&rdquo;到达成都城厢站，再站内无缝衔接&ldquo;长江班列&rdquo;运往武汉，接驳长江班轮到达华东地区，实现国际多式联运。截止目前，&ldquo;长江班列+中欧班列&rdquo;联运累计发行超140列次，7000标箱，推动了&ldquo;长江班列+中欧班列&rdquo;国际国内双通道互联互通、无缝衔接，为客户提供了高效、安全、稳定、便捷的跨境物流运输服务，为欧洲到华东地区货物运输提供了新的选择，可进一步推动泛亚泛欧铁路大通道延伸拓展。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news5_2}" alt="" width="1080" height="811" /></p>
        <p style="text-align: center;"><strong>拓展高附加值货物</strong></p>
        <p style="text-align: center;"><strong>助力长江经济带发展</strong></p>
        <p>&nbsp; &nbsp; &nbsp; 我省正处于工业化中期向中后期转型推进的关键阶段，发展方式、发展动力、发展领域、发展质量深刻变革，蜀道铁路运营集团大力跟踪沿线市场需求，研究货源分布及流向，围绕更好服务经济社会发展、降低企业物流成本做好开行网络和运输价格顶层设计，不断优化运输服务组织，不断调整发运货物品类，从最初的矿产、粮食等初级产品，到汽车配件、机械设备、钒钛产品等高附加值货物，不断贴合我省产业发展所需，以高效率低成本的物流运输吸引高端产业布局四川，助推成渝地区双城经济圈与中部地区、长三角地区加速融合和产业梯次升级发展。</p>
        <p>&nbsp;</p>
        <p style="text-align: center;"><strong>搭建服务平台</strong></p>
        <p style="text-align: center;"><strong>提升班列管理效能</strong></p>
        <p>随着&ldquo;长江班列&rdquo;规模持续扩大，传统的人工操作已无法满足企业对班列服务的需求，面临业务模式新、基础工作量大、票据处理工作繁杂等诸多困难，蜀道铁路运营集团研发并投用了蜀道班列多式联运公共服务平台，实现订单委托、受理、制票、发运及结算全过程在线处理，提升业务人员结算效率，确保物流运输服务的准确性和可靠性。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news5_3}" alt="" width="1080" height="529" /></p>
        <p>&nbsp;</p>
        <p>&nbsp; &nbsp; &nbsp; 未来，&ldquo;长江班列&rdquo;将积极探索&ldquo;物流+金融+贸易&rdquo;&ldquo;江海直达+长江班列&rdquo;等新的业态模式，以高效率、低成本的物流优势不断优化长江&ldquo;黄金通道&rdquo;综合运输结构，服务区域实体企业和高端制造业入川出海，进一步助力长江经济带高质量发展。</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>`,
    releaseDate: '2024-07-18 22:55:21',
    isTop: false,
    isHot: false,
    hits: Random.natural(10, 100)
  },
  {
    id: 6,
    title: '21532标箱！一季度“长江班列”热辣滚烫',
    summary: '3月31日，随着一声悠长的汽笛声划破天空，满载板材的“长江班列”迎着朝阳缓缓驶出成都国际铁路港，驶往湖北武汉香炉山站。至此，一季度“长江班列”累计发运434列，其中东出251列，西进183列，共21532个标准集装箱，以“铁水联运”优势加速聚合区域要素，正成为牵引区域融通新力量。',
    thumbnail: { path: news6_1 },
    content: `<p>&nbsp; &nbsp; &nbsp; 3月31日，随着一声悠长的汽笛声划破天空，满载板材的&ldquo;长江班列&rdquo;迎着朝阳缓缓驶出成都国际铁路港，驶往湖北武汉香炉山站。至此，一季度&ldquo;长江班列&rdquo;累计发运434列，其中东出251列，西进183列，共21532个标准集装箱，以&ldquo;铁水联运&rdquo;优势加速聚合区域要素，正成为牵引区域融通新力量。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news6_1}" alt="" width="1080" height="810" /></p>
        <p style="text-align: center;"><span style="font-size: 24pt;"><strong>跑出物流降本增效&ldquo;加速度&rdquo;</strong></span></p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;长江班列&rdquo;充分发挥全天候运输通道优势，以&ldquo;铁水联运&rdquo;大幅缩短运输时间，有效降低企业物流综合成本，使川渝地区与长江中下游地区货物交流效率显著提升。以从成都至上海地区为例，在航路拥堵期间，货物经&ldquo;长江班列&rdquo;到达武汉后无缝衔接水运至上海，平均运输时间较传统的水运直达方式至少缩短15天。自班列运营以来，蜀道集团充分发挥省级平台优势，不断加强省内东向出川货源组织力度，持续提高班列平台服务质量。3月，班列单月开行量和货物发送量创历史新高，达168列，环比增长22%，运载货物22.3万吨，提前半年达到150列/月的规划开行目标。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news6_2}" alt="" width="1080" height="608" /></p>
        <p style="text-align: center;"><span style="font-size: 24pt;"><strong>助力全省高端制造业&ldquo;再升级&rdquo;</strong></span></p>
        <p>&nbsp; &nbsp; &nbsp; 按照&ldquo;链接产销两端、精准配套供应链&rdquo;的原则，坚持&ldquo;试点引导、逐步拓展、有序推进&rdquo;的工作路径，&ldquo;长江班列&rdquo;积极助力各类产业发展要素导入，不断提升供应链韧性和服务水平，提升资源要素配置效率。当前，&ldquo;长江班列&rdquo;的货物运输已从前期的以省内资源消耗型企业生产原料为主，逐步发展为生产原料与汽车零部件、机械装备、食品医药、百货类等30余种高附加值产品并重的运输格局。同时，2月以来，&ldquo;长江班列&rdquo;东向出川班列数量环比增长超50%，以高效率、低成本的物流优势助力高端制造业在全省布局发展，正成为推动产业升级和营商环境优化的催化剂。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news6_3}" alt="" width="1080" height="646" /></p>
        <p style="text-align: center;"><span style="font-size: 24pt;"><strong>催生长江经济带区域合作&ldquo;大平台&rdquo;</strong></span></p>
        <p>&nbsp; &nbsp; &nbsp; &ldquo;长江班列&rdquo;在通道建设上持续推进、统筹规划，积极开辟新线路，不断增强境内外通达能力，目前已从发运初期的单一线路、少量站点快速拓展到30条联运线路、17个到发站，初步形成&ldquo;以成都至荆州为主线、其他多线协同联动&rdquo;的货物运行网络格局。积极与&ldquo;长江班列&rdquo;沿线30余家企事业单位及武汉、荆州、宜昌等地市交流沟通，通过完善&ldquo;长江班列&rdquo;物流节点、搭建班列资源和数据信息共享平台、探索&ldquo;物流+金融+贸易&rdquo;供应链服务模式等务实举措，合力共商共建高质量&ldquo;长江班列&rdquo;物流服务体系，提升长江黄金水道运输效率，促进长江经济带联动发展。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news6_4}" alt="" width="1080" height="720" /></p>
        <p style="text-align: center;"><span style="font-size: 10pt; color: #ced4d9;">在黄石</span><span style="font-size: 10pt; color: #ced4d9;">举行&ldquo;江海直达+长江班列&rdquo;战略合作洽谈会暨签约仪式</span></p>
        <p>&nbsp; &nbsp; &nbsp; 预计到2024年底，&ldquo;长江班列&rdquo;将实现双向运输1800列，运输货物超240万吨。蜀道集团将充分发挥&ldquo;长江班列&rdquo;链接上下游的纽带作用，进一步加强与沿线各省的交流合作，继续在提质增效降本上下功夫，不断提升班列服务能力，更好服务融入国内国际双循环发展格局，助推我省高质量发展和高水平对外开放。</p>`,
    releaseDate: '2024-04-03 18:11:22',
    isTop: false,
    isHot: true,
    hits: Random.natural(10, 100)
  },
  {
    id: 7,
    title: '蜀道铁路运营集团赴荆州市地方铁路有限公司考察',
    summary: '7月10日至11日，蜀道铁路运营集团党委委员、副总经理刘应贵带队赴荆州市地方铁路有限公司开展项目考察，双方围绕“长江班列”开行、运贸一体、多式联运业务等方面进行深入交流。荆州市地方铁路有限公司党委书记、董事长王刚参加座谈。',
    thumbnail: { path: news7_1 },
    content: `<p>&nbsp; &nbsp; &nbsp; 7月10日至11日，蜀道铁路运营集团党委委员、副总经理刘应贵带队赴荆州市地方铁路有限公司开展项目考察，双方围绕&ldquo;长江班列&rdquo;开行、运贸一体、多式联运业务等方面进行深入交流。荆州市地方铁路有限公司党委书记、董事长王刚参加座谈。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news7_1}" alt="" width="1080" height="809" /></p>
        <p>&nbsp; &nbsp; &nbsp; <strong>刘应贵</strong>一行先后前往沙市站、荆州市盐卡港区、旺港码头、物流园区，详细了解了荆州市地方铁路有限公司的业务运营、铁路专用线、港口规划、物流园区的建设等情况。&nbsp; &nbsp; &nbsp;</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news7_2}" alt="" width="1080" height="810" /><img style="display: block; margin-left: auto; margin-right: auto;" src="${news7_3}" alt="" width="1080" height="810" /><img style="display: block; margin-left: auto; margin-right: auto;" src="${news7_4}" alt="" width="1080" height="810" /></p>
        <p>&nbsp; &nbsp; &nbsp; <strong>刘应贵</strong>介绍了蜀道铁路运营集团的目前主要业务板块及发展情况，并感谢对&ldquo;长江班列&rdquo;开行等给予的支持和帮助。</p>
        <p><strong>刘应贵表示</strong></p>
        <p>&nbsp; &nbsp; &nbsp; 荆州市地方铁路有限公司作为历史底蕴深厚的地方铁路运营单位，守正创新，奋力开拓，形成了鲜明的企业文化，取得了优秀的经营成效。双方要以&ldquo;长江班列&rdquo;为契机，以铁水联运为着力点，共同加强项目的沟通交流，形成更广泛更深入的业务合作。</p>
        <p>&nbsp; &nbsp; &nbsp; <strong>王刚</strong>对刘应贵一行的来访表示热烈欢迎，对蜀道铁路运营集团开行&ldquo;长江班列&rdquo;取得的成绩给予高度的认可。</p>
        <p><strong>王刚表示</strong></p>
        <p>&nbsp; &nbsp; &nbsp; 蜀道铁路运营集团作为四川省地方铁路运营企业，围绕&ldquo;铁路+&rdquo;产业链积极拓展布局，在合资铁路建设、铁路运输、运贸一体、专用线运维等业务方面积累值得借鉴的丰富经验。双方要在&ldquo;长江班列&rdquo;形成的合作基础上，不断深化共识，密切交流，推动铁水多式联运项目取得更长足的发展。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news7_5}" alt="" width="1080" height="665" /></p>
        <p>&nbsp; &nbsp; &nbsp; 荆州市产业投资发展集团有限公司、荆州市荆州开发集团有限公司、荆州市地方铁路有限公司相关领导、部门陪同考察。蜀道铁路运营集团投资发展部、铁路物流公司、铁路运维公司相关负责人参加考察。</p>`,
    releaseDate: '2024-07-12 20:40:54',
    isTop: false,
    isHot: true,
    hits: Random.natural(10, 100)
  },
  {
    id: 8,
    title: '蜀道铁路运营集团开展“长江班列”合作客户回访',
    summary: '6月26日，蜀道铁路运营集团党委书记、董事长张锋带队，分别赴中铁集装箱成都分公司、成都海程邦达供应链公司进行“长江班列”合作回访。中铁集装箱成都分公司总经理周军，海程邦达供应链股份公司副总裁周俊波参加座谈。',
    thumbnail: { path: news8_1 },
    content: `<p>&nbsp;&nbsp;6月26日，蜀道铁路运营集团党委书记、董事长张锋带队，分别赴中铁集装箱成都分公司、成都海程邦达供应链公司进行&ldquo;长江班列&rdquo;合作回访。中铁集装箱成都分公司总经理周军，海程邦达供应链股份公司副总裁周俊波参加座谈。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news8_1}" alt="" width="1080" height="720" /></p>
        <p>&nbsp; &nbsp; &nbsp; <strong>张锋</strong>对两家公司长期以来对&ldquo;长江班列&rdquo;的支持表示感谢，并介绍了班列目前开行情况。</p>
        <p><strong>张锋表示</strong></p>
        <p>&nbsp;&nbsp;“长江班列”开行半年以来，得到市场各方的高度认可，在广大客户的大力支持下，目前已实现了常态化开行。此次回访，主要目的是进一步了解客户对&ldquo;长江班列&rdquo;的真实需求，收集各方意见建议，着力推动合作共赢。下一步，&ldquo;长江班列&rdquo;平台将按照省委省政府决策部署，始终秉持开放合作的态度，进一步提升政策的灵活性和精准度，确保更加贴近市场真实需求，携手各方共同推动&ldquo;长江班列&rdquo;越开越好，切实发挥出最大通道价值。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news8_2}" alt="" width="1080" height="811" /></p>
        <p>&nbsp;&nbsp;回访中，各方一致认为，&ldquo;长江班列&rdquo;通江达海、互济东西，是助推长江经济带高质量发展的重要基础设施，有效提升了货物交换效率、节约了物流运输成本，通过各方精诚合作，能够进一步实现班列稳定开行，为服务四川和沿线各地经济发展作出更大贡献。</p>
        <p>&nbsp;&nbsp;蜀道铁路运营集团相关部门、蜀道铁路物流公司相关负责人参加回访。</p>
        <p>&nbsp;</p>`,
    releaseDate: '2024-06-26 18:30:22',
    isTop: true,
    isHot: true,
    hits: Random.natural(10, 100)
  },
  {
    id: 9,
    title: '四川“长江班列”实现常态化运行 全新东向出川物流大通道初步成形',
    summary: '6月3日，一列来自湖北的“长江班列”抵达位于成都的城厢站，装载的汽车零配件随即被转运到附近汽车生产厂。“从上海到成都，半个月就运到了，比以前纯走水路至少快5天！”组货公司物流部高级主管葛文静说，这条铁水联运的新路线已成为公司货物西向抵川的必选。',
    thumbnail: { path: news9_1 },
    content: `<p>&nbsp; &nbsp; 6月3日，一列来自湖北的&ldquo;长江班列&rdquo;抵达位于成都的城厢站，装载的汽车零配件随即被转运到附近汽车生产厂。&ldquo;从上海到成都，半个月就运到了，比以前纯走水路至少快5天！&rdquo;组货公司物流部高级主管葛文静说，这条铁水联运的新路线已成为公司货物西向抵川的必选。</p>
        <p>&nbsp; &nbsp; 服务于长江经济带的&ldquo;长江班列&rdquo;，以四川和湖北为铁路运营段，绕过三峡翻坝段，采取铁水联运方式，助力特色产品出川、生产原料进川，于2023年12月10日首发。</p>
        <p>&nbsp; &nbsp; 数据显示，截至今年5月底，近半年时间，&ldquo;长江班列&rdquo;已开行769列，运输货物38422标箱、102万吨，形成开行线路30条，发到站点18个，东出班列与西进班列数量比例基本呈现1:1的状态，并与抵川中欧班列实现了有效衔接，一条新的东向出川物流大通道已初步构建成形。</p>
        <p style="text-align: center;"><span style="font-size: 14pt;"><strong>新谋划</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 14pt;"><strong>铁水联运，打造东向出川新通道</strong></span></p>
        <p>&nbsp; &nbsp; 葛文静所在的成都交投国际供应链管理有限公司，代理了进川大部分的汽车零配件、化工类货物。其中，汽车零配件的客户是沃尔沃、一汽等在川汽车生产厂。以前，这些货物主要通过水运运输，逆长江而上，到达重庆果园港或泸州港，再经汽车或火车转运到目的地，全程用时20&mdash;30天。</p>
        <p>&nbsp; &nbsp; 无论是在重庆或在四川上岸，一个绕不开的&ldquo;坎&rdquo;是三峡枢纽。长江&ldquo;黄金水道&rdquo;辐射的长江经济带横跨我国东中西三大区域，串联了长三角、长江中游和成渝三大城市群，货运量占全国40%以上。长江三峡枢纽投运20年来过闸运量快速增长，2022年就达设计限值1.6亿吨，平均待闸时间已从2011年的17小时（0.7天）增加至2022年的223小时（约9.3天），货船排队等候已成常态。</p>
        <p>&nbsp; &nbsp; &ldquo;汽车零配件一般排队一周左右。&rdquo;葛文静说，有一次排队时间较长，比平时晚了好几天，工厂等着零配件上线，客户急得不停打电话催。</p>
        <p>&nbsp; &nbsp; 据调研，长江干线的主要货种，公路运费是铁路运费的3倍左右，铁路运费是水路运费的4倍左右。因此，虽然水路运输时间长，但仍有不少货主选择长江航运。对于&ldquo;翻坝难&rdquo;这一&ldquo;肠梗阻&rdquo;，近年来，陆续有公路翻坝、铁路翻坝方式出现。</p>
        <p>&nbsp; &nbsp; 从现实来看，公路翻坝方式运能较低、运输承载能力有限，并且需另行计算装卸费、公路运费、通行费等，整体翻坝运输成本较高，比如，干散货类需增加翻坝费用约80元/吨，集装箱增加翻坝费用约1500元/标箱。而铁路运输承载能力大、综合运价较低。综合考量下，四川决定开行四川至湖北的&ldquo;长江班列&rdquo;，由蜀道集团作为省级运营主体，采取铁水联运的方式，分流货物上岸，有效缓解过闸拥堵。</p>
        <p>&nbsp; &nbsp; 蜀道铁路运营集团董事长张锋介绍，近半年来，省级平台与长江中游联运港口积极联动，不断优化转运效率，已初步形成&ldquo;以成都至荆州为主线、其他多线协同联动&rdquo;的多点多线货物运行网络联动格局，省内有成都、眉山、攀枝花、达州等发到站，省外有荆州港、鄂州港、阳逻港等多个港口，形成了对三峡通闸拥堵的有效分流，构建起一条快捷价优的东向出川物流新通道。</p>
        <p>&nbsp; &nbsp; 从运行情况来看，目前&ldquo;长江班列&rdquo;较原有水运线路平均缩短运行时间约5&mdash;10天，极大地满足了适水货物的时效需求。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news9_1}" alt="" width="1080" height="810" /></p>
        <p style="text-align: center;"><span style="font-size: 10pt; color: #ced4d9;">成都青白江城厢中心站，&ldquo;长江班列&rdquo;所运载的汽车零配件正在卸货转运。</span></p>
        <p style="text-align: center;"><span style="font-size: 14pt;"><strong>新通道</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 14pt;"><strong>平均每天开行5列 已实现江铁海联运</strong></span></p>
        <p>&nbsp; &nbsp; 从数据来看，不到半年，&ldquo;长江班列&rdquo;就开行了769列，平均每天4.5列，3月以来已达到平均每天5列。</p>
        <p>&nbsp; &nbsp; 一条新班列，缘何能吸引这么多货源？</p>
        <p>&nbsp; &nbsp; 近半年来，经荆州市地方铁路有限公司到发的&ldquo;长江班列&rdquo;已有7700余箱。该公司副总工程师李春华表示，&ldquo;长江班列&rdquo;在时效和成本上均有优势，不仅大幅缩短了货物运输时间，在政策引导支持下，综合运输成本也比传统方式低，实现了物畅其流。</p>
        <p>&nbsp; &nbsp; &ldquo;客户都是用&lsquo;脚&rsquo;投票的。&rdquo;在葛文静看来，在整体运价差别不大的情况下，&ldquo;长江班列&rdquo;把汽车零配件等生产类原料更快地送到客户手中，有助于提高生产运转周期，对客户有着极强的吸引力。</p>
        <p>&nbsp; &nbsp; 为破解&ldquo;翻坝难&rdquo;而开行的&ldquo;长江班列&rdquo;，正在成为新通道。今年一季度，三峡北线船闸和葛洲坝一号船闸检修为期45天，待闸压力进一步加大，通过&ldquo;长江班列&rdquo;有效解决了中粮、中储粮、川威等十余家省内相关企业对生产资料时效性的需求，切实降低了企业的物流综合成本。</p>
        <p>&nbsp; &nbsp; 再细剖数据，开行的769列班列中，东出404列、西进365列，出川、进川班列数比例达到1:1。四川&ldquo;消费型&rdquo;经济特征明显，长期以来，到达货物量都高于运出货物量，缘何&ldquo;长江班列&rdquo;的出川数更多？</p>
        <p>&nbsp; &nbsp; 攀西地区钛精矿年产四五百万吨，约有三百万吨外销。今年以来&ldquo;长江班列&rdquo;，几乎每天都有2&mdash;3列搭载钛精矿的班列，从位于攀枝花的迤资、青杠等火车站驶出。</p>
        <p>&nbsp; &nbsp; &ldquo;去年一季度，通过铁路运输到我省主要港口&mdash;&mdash;泸州港下水的只有2万吨左右。&rdquo;蜀道铁路运营集团运输营销部负责人罗胜摆出一组数据：&ldquo;而今年一季度通过&lsquo;长江班列&rsquo;发运的有16万吨，更多货物被吸引&lsquo;公转铁&rsquo;了。&rdquo;</p>
        <p>&nbsp; &nbsp; 和中欧班列有效衔接，成为了最大&ldquo;增量&rdquo;。&ldquo;每天抵达城厢站的中欧班列中，约有1/3的集装箱需要转运到华东、华南地区，其中70%通过&lsquo;长江班列&rsquo;运输。&rdquo;成都海程帮达供应链管理有限公司&ldquo;长江班列&rdquo;项目现场负责人黄昌照说，更快的时效、更低的价格，以及便捷的中转，让两大班列在今年3月实现衔接。截至目前，仅城厢站就已发运&ldquo;长江班列&rdquo;逾6500标箱。</p>
        <p><img style="display: block; margin-left: auto; margin-right: auto;" src="${news9_2}" alt="" width="1080" height="810" /></p>
        <p style="text-align: center;"><span style="font-size: 10pt; color: #ced4d9;">成都青白江城厢中心站，&ldquo;长江班列&rdquo;所转运分拨的欧洲地区货物正等待牵引发出。</span></p>
        <p>&nbsp; &nbsp; &ldquo;长江班列&rdquo;还在向更远的江海延伸。今年4月下旬，长江沿线的政府、企业联动，成功将780吨巴西进口的纸浆，从浙江舟山经湖北黄石、荆州运抵四川德阳，以&ldquo;江海直达+长江班列&rdquo;江铁海联运新模式实现了1.6万余公里超长距离的跨境一站式物流交付，搭建起&ldquo;长江班列&rdquo;与国际航运间的快速连接。</p>
        <p>&nbsp; &nbsp; 蜀道集团正携手长江中下游的荆州、鄂州、黄石、岳阳、舟山等，打造&ldquo;江海直达+长江班列&rdquo;的&ldquo;1+N+1&rdquo;水铁联运新模式，N为长江沿岸的多个港口。&ldquo;&lsquo;江海直达&rsquo;最远可覆盖长江90%的航段。&rdquo;在舟山市港航和口岸管理局副局长曹军看来，这种携手合作将优化综合运输结构，做大长江中上游港口联运量，更好地服务长江经济带发展。</p>
        <p>&nbsp; &nbsp; 目前，&ldquo;长江班列&rdquo;货物运输种类已从省内资源消耗型企业生产原料为主，逐步扩展到汽车零配件、机械装备、食品医药、百货类等30余种高附加值产品。</p>
        <p>&nbsp; &nbsp; 张锋表示，&ldquo;长江班列&rdquo;不仅是物流大通道，更要成为产业大通道，&ldquo;要以高效率、低成本的物流优势，助力高端制造业在全省布局发展，并吸引更多先进生产要素在四川集聚。&rdquo;</p>`,
    releaseDate: '2024-06-05 17:30:18',
    isTop: false,
    isHot: true,
    hits: Random.natural(10, 100)
  }
];

export const _news_ = m__mock(news.sort((a, b) => (a.releaseDate > b.releaseDate ? -1 : 1)));
