import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import authcApi from '@website/apis/authc';
export default {
  __name: 'app',
  setup(__props) {
    const isLogin = ref(false);
    authcApi.customer().then(customer => {
      isLogin.value = !!customer;
    });
    return (_ctx, _cache) => {
      const _component_layout_header = _resolveComponent("layout-header");
      const _component_router_view = _resolveComponent("router-view");
      const _component_layout_footer = _resolveComponent("layout-footer");
      const _component_layout_party = _resolveComponent("layout-party");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_layout_header, {
        isLogin: isLogin.value
      }, null, 8, ["isLogin"]), _createVNode(_component_router_view), _createVNode(_component_layout_footer), _createVNode(_component_layout_party)], 64);
    };
  }
};