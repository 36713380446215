import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ba240ea"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout-main"
};
import LayoutWrap from './layout-wrap.vue';
export default {
  __name: 'layout-main',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(LayoutWrap, null, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      })]);
    };
  }
};