import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4e3f25fd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout-banner"
};
import LayoutWrap from './layout-wrap.vue';
export default {
  __name: 'layout-banner',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {
        wrap: LayoutWrap
      })]);
    };
  }
};